@media (max-width:1130px) and (min-width:556px) {
    .App {
        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__greeting {
            &_content {
                flex-direction: column !important;
                margin-left: 0 !important;
                align-items: center;
            }
        }

        &__rules {
            &_heading {
                text-align: center;
            }

            &_text {
                text-align: center;
            }

            &_grid {
                display: flex;

                flex-direction: column !important;
            }

            &_rule {
                display: flex;
                flex-direction: column;
                align-items: center;

                &_description {
                    text-align: center;
                    margin-bottom: 10px;
                }
            }

            &_buttons {
                display: flex;

                flex-direction: column;
            }

            &_presentation {
                margin-top: 10px !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;


            }

            &_content {
                padding: 0 !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &__demonstration {
            &_images {
                //  display: none !important;
                width: 100% !important;
            }

            &_content {
                align-items: center;
                gap: 10px !important;
                flex-direction: column !important;
                margin-left: 0 !important;
                width: 100% !important;
            }
        }

        &__footer {
            &_grid {

                .super-bold {
                    display: none;
                }

                div {
                    // width: inherit;
                }

                display: flex !important;
                flex-direction: column;
                width: 100vw;
                margin: 0 auto;
                align-items: center;
            }
        }

        &__advantages {
            &__wrapper {
                display: flex;
                flex-direction: column !important;
                align-items: center !important;
            }
        }

    }
}