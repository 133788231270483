@import "colors.scss";

.pdd-cards {
    gap: 30px;

    .pdd-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $gray;
        // height: 180px;
    }

    p {
        text-align: center;
        margin: 10px 0 0 0 !important;
        line-height: 150%;
    }

    img {
        // width: 132px;
        height: 132px;
    }
}

.pdd-penalties-content {
    .item1, .item2{
        img{
            width: 50%;
        }
    }
    .pdd-penalties {
        display: flex;

        .pdd-penalty {
            border-radius: 20px;
            border: 1px solid $gray;
            // width: 50%;
        }
    }
}
.cursor{
    cursor: pointer;
}
.text-tooltip{
    margin-top: 20px !important;
    color: gray;
}
.progress-bar {
    height: 1px;
}

.flex {
    display: flex;
}

.padding-none {
    padding: 0 !important;
}

.header-border {
    border-left: 1px solid $gray;
    border-right: 1px solid $gray;
}

// .container{
//     border-left: 1px solid $gray;
//     border-right: 1px solid $gray;
// }
.gap-10 {
    gap: 10px;
}

.align-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.active {
    color: $green;
}

.active-button {
    border: 1px solid $hover-green !important;
}

.content-block {
    // background-color: white;
    border-radius: 32px;
}

.mini-block {
    background-color: white;
    width: 376px;
}

.uram {
    font-weight: inherit;
    font-size: inherit;
    color: $green;
}

.hidden {
    display: none;
}

ul,
li,
a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
}
.row-title-text{
    padding-left: 30px;
}
.faq-title{
    display: none !important;
}
.mb-20{
    margin-bottom: 20px;
}
body {
    font-family: "Roboto", sans-serif;
}

.super-bold {
    font-weight: 700;
}

.logo-bg {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;

    svg {
        transition: all .200s ease-in-out;
    }
}

a {
    color: black;

    &:hover {
        color: gray
    }
}

.selected {
    border: 1px solid $green !important;
}
.list-number{
    background-color: $gray;
    border: 1px solid transparent;
    border-radius: 99px;
    padding: 10px 16px;
    margin-right: 10px;
}
.wrapper{
    display: flex;
    justify-content: center;
}
.column{
    flex-direction: column;
}
.justify-center{
    justify-content: center;
}
.mt-50{
    margin-top: 50px;
}
.mb-50{
    margin-bottom: 50px;
}
.mt-20{
    margin-top: 20px;
}
.gap-30{
    gap: 30px;
}
.gap-50{
    gap: 50px;
}
.card-block {
    // width: 428px;
    width: 100% !important;
    border-radius: 28px;
    background: #f6f6f7;

    .background {
        width: inherit;
        height: 300px;

        img {
            border-top-left-radius: 28px;
            border-top-right-radius: 28px;

            width: inherit;
            height: inherit;
        }
    }

    &__text {
        width: 428px;
        padding: 1.75rem;
        gap: 30px;
    }

}