//react-bootstrap carousel styles
.carousel-inner{
    width: 100vw;
    height: 300px;
   }
@media (max-width:555px) {
    .App {
        &__cities{
            &_name{
                display: block;
            }
            &_cards{
                display: none !important; 
            }
            &_slider{
                display: block !important;
                img{
                    width: 100vw;
                    height: 350px;
                }
            }
            &_cards{
                gap: 10px !important;
                flex-direction: column;
                img{
                    width: 300px !important;
                    height: 240px !important;
                }
                &:hover{
                    background: red;
                }
            }
        }
        &__scooter{
            h4,h5{
                text-align: center;
            }
            &_content{
                flex-direction: column;
            }
            img{
                height: 200px;
                width: 200px;
            }
            &_sub-block{
                flex-direction: column;
            }
            &_description{
                padding: 10px;
            }
            &_image{
                display: flex;
                justify-content: center;
            }
         }
        &__tariffs{
            &_content {
                padding: 26px 36px;
            }
            &_blocks{
                flex-direction: column;
            }
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__greeting {
            &_heading {
                margin-bottom: 10px;
                text-align: center;

            }

            &_sub-text {
                width: 100% !important;
                text-align: center;
                margin-top: 15px !important;
            }

            &_image-block {
                // display: none !important;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    border-radius: 0 !important;
                    width: 100vw !important;
                    height: 100% !important;

                }

                // width: 300px !important;
                // height: 200px !important;
            }

            &_content {
                flex-direction: column !important;
                margin-left: 0 !important;
                width: 100% !important;
                // align-items: center;
            }
        }

        &__video {
            video {
                width: 100vw !important;
            }
        }

        &__rules {
            &_heading {
                text-align: center;
            }

            &_text {
                text-align: center;
            }

            &_grid {
                display: flex;

                flex-direction: column !important;
            }

            &_rule {
                display: flex;
                flex-direction: column;
                align-items: center;

                &_description {
                    text-align: center;
                    margin-bottom: 10px;
                }
            }

            &_buttons {

                flex-direction: row;

                p {
                    margin: 0 !important;
                }
            }

            &_presentation {
                margin-top: 10px !important;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;


            }

            &_content {
                padding: 0 !important;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &__demonstration {

            &_content {
                align-items: center;
                gap: 10px !important;
                flex-direction: column !important;
                margin-left: 0 !important;
                width: 100% !important;
            }

            &_buttons {
                justify-content: center;
            }

            &_list {
                flex-direction: column;
                gap: 30px !important;
            }

            &_text {
                text-align: center;
            }
        }

        &__footer {
            &_grid {

                .super-bold {
                    display: none;
                }


        

                display: flex !important;
                flex-direction: column;
                width: 100vw;
                margin: 0 auto;
                align-items: center;
            }
        }

        &__advantages {
            .mini-block {
                width: 100% !important;
            }

            &__wrapper {
                display: flex;
                flex-direction: column !important;
                align-items: center !important;
            }
        }

        &__greting {
            &_text-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .button-group {
        width: 95vw !important;
        gap: 0 !important;
    }

    .card-block {
        width: 100vw;

        &__text {
            width: 100% !important;
        }
    }

    .pdd-cards {
        flex-direction: column;

        .pdd-card {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        p {
            text-align: center;
        }
    }
}