@media (min-width:1130px) {
    .App {
        &__cities{
            &_name{
                display: none;   
            }
            &_slider{
                display: none !important; 
            }
        }
        &__scooter {
            &_description {
                width: 800px;
            }
        }

        &__tariffs {
            &_content {
                padding: 72px 96px;
            }

            &_block {
                gap: 30px !important;
            }
        }

        &__demonstration {
            &_images {
                width: 500px !important;
            }

            &_list {
                display: flex;
                gap: 20px;
            }

            &_show {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &_content {
                justify-content: center;
            }

            &_heading {
                text-align: center;
            }
        }

        &__video {
            width: 100%;

            video {
                width: inherit !important;
            }
        }

        &__wrapper {

            max-width: 1140px;
            margin: 0 auto;
        }

        &__greeting {
            &_image-block {
                height: 360px;
                width: 536px;

                img {
                    height: inherit;
                    width: inherit;
                    border-radius: 12px;
                }
            }
        }
    }
}