 @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
 @import 'classes.scss';
 @import "./responsive/adaptive.scss";
 @import "./responsive/desktop.scss";
 @import "./responsive/laptop.scss";
 @import "./responsive/mobile.scss";
 @import "./responsive/super-mobile.scss";
 @import "colors.scss";
 @import "animations.scss";

 html {
     scroll-behavior: unset !important;
 }

 ::-webkit-scrollbar {
     width: 8px;

 }


 ::-webkit-scrollbar-track {
     background-color: #e4e4e4;
     border-radius: 100px;
 }

 ::-webkit-scrollbar-thumb {
     background-color: #777381;
     border-radius: 100px;
 }

 .button-group_text {

     h1,
     p {
         margin: 0;
     }
 }


 .button-group {
     border: 1px solid $gray;
     padding: 20px 15px;
     border-radius: 10px;
     gap: 60px;
 }

 .button-group_buttons {
     gap: 10px;
     ;

     a {
         padding: 2px;
         border-radius: 10px;
         border: 1px solid $gray;
     }
 }



 .App__demonstration_heading {
     margin-bottom: 50px;
 }

 .App {
    &__scooter{
        h4,h5 {
            text-align: center;
        }
    }
     &__wrapper {
         transition: all .600s ease-in-out;
     }

     &__header {
         position: sticky;
         top: 0;
         z-index: 100;
         display: flex;
         justify-content: space-between;
         justify-items: center;
         gap: 10px;

         img {
             width: 60px;
             height: 60px;
         }

         &_logo {
             cursor: pointer;
         }

         ul {
             gap: 30px;
             display: flex;
             margin-bottom: 0;



             li {
                 color: #777381;
                 cursor: pointer;
                 position: relative;
                 font-weight: 500;


                 &:hover {
                     color: black;
                     transition: color .2s ease-in-out;
                 }
             }
         }

         &_button {
             background-color: $green;
             border-radius: 40px;
             color: white;
             cursor: pointer;
             border: 1px solid transparent;
             padding: 10px 15px;

             &:hover {
                 background-color: $hover-green;
                 color: $hover-white;
             }

         }

     }

     &__greeting {
         display: flex;

         &_content {
             margin-left: 96px;
             margin-top: 96px;
             width: 740px;
             display: flex;
             align-items: center;
         }

         &_sub-text {
             width: 460px;
             margin-top: 20px;
             line-height: 120%;
         }

         &_image-block {

             img {
                 height: inherit;
                 width: inherit;
                 border-radius: 32px;
             }

         }
     }

     &__advantages {
        svg{
            height: 50px;
            width: 50px;
        }
         &_grid {

             width: 100%;
             display: grid;
             grid-template-columns: repeat(2, 2fr);
             grid-gap: 10px;
         }

         &_block {
             position: relative;
             color: white;
             padding: 30px;
             width: 100%;
             height: 100%;
             background: white;
             border-radius: 50px;
             aspect-ratio: 1;
         }

         &_item {
             width: inherit;
             height: inherit;

             &_title {
                 margin-bottom: 50px;
                 text-align: center;
             }

             &_text {
                 // font-weight: 700;
                 // font-size: 24px;
                 margin-bottom: 20px;
             }
         }

         &_content {
             padding: 36px;
         }

         &_image {
             height: 133px;

             img {
                 height: inherit;
             }
         }

         &_heading {
             margin-top: 20px;
         }

         &_text {
             margin-top: 15px;
             line-height: 140%;
             font-weight: 400;
         }

         &__wrapper {
             display: flex;
             justify-content: center;
             gap: 10px;
         }

         margin-top: 100px;

         &__content {
             padding: 32px;
         }
     }

     &__demonstration {
         background-color: #fff;

         &_heading {

             line-height: 120% !important;
         }

         &_content {
             padding: 72px 96px;
             display: flex;
             gap: 100px;
         }

         &_show {
             display: flex;
             flex-direction: column;
             align-items: center;
         }

         &_list {
             display: flex;

             gap: 10px;
             margin-bottom: 50px;

             p,
             h1 {
                 margin: 0 !important;
             }

             p {
                 font-size: 14px;
             }

             ul {
                 display: flex;
                 flex-direction: column;

                 gap: 5px;

                 li {
                     cursor: pointer;

                     span {
                         padding: 4px;
                         border-radius: 28px;
                         background-color: $gray;
                         padding: 10px 16px;
                     }
                 }
             }
         }

         &_images {
             height: 700px;

             p {
                 text-align: center;
             }

             img {
                 height: inherit;
             }
         }

         &_buttons {
             margin-top: 20px;
             display: flex;

             gap: 10px;

             img {
                 cursor: pointer;
                 //  width: 160px;
                 height: 50px;
             }

             &_description {
                 width: 312px;
             }

             &_grid {
                 display: flex;
             }
         }

     }

     &__rules {
         background: #fff;
         border-radius: 0 !important;

         &_content {
             padding: 72px 96px;
         }

         &_presentation {
             margin-top: 50px;
         }

         h1 {
             display: inline;
             padding: 1px;
         }

         h3 {
             margin-top: 30px
         }

         p {
             margin-top: 15px;
         }


         &_grid {
             margin-bottom: 50px;
             display: flex;
             align-items: center;
             justify-content: space-between;
         }

         &_description {
             margin-top: 20px;
             line-height: 120%;
             width: 70%;
             height: 50px;

         }

         &_rule {
             &_heading {
                 text-align: center;
             }

         }
     }

     &__footer {
         &_grid {
             display: flex;
             justify-content: space-around;
             align-items: center;

             div {
                 align-items: center;
                 justify-content: space-around;
             }
         }

         margin-top: 100px;

         &_company {
             img {
                 width: 80px;
                 height: 80px;
             }

             p {
                 margin: 1px 0 0 0;
             }
         }

         &_contacts {
             a {
                 display: flex;
                 flex-direction: column;
                 align-items: center;
             }
         }
     }

     &__faq {
         margin-top: 60px;

         &_content {
             border: 1px solid gainsboro;
         }

         &_heading {
             text-align: center;
         }

         &_list {
             display: flex;
             flex-direction: column;
         }
     }

     &__video {
         background: white;
         margin-top: 60px;

         video {
             width: inherit;

         }
     }

     &__cities {
         margin-top: 30px;

         h1,
         p {
             text-align: center;
         }

         &_images {
             display: flex;
             justify-content: center;
             flex-direction: column;

             div {
                 width: 100vw;
             }
         }

     }

     &__tariffs {
        
        &_content{
            p{
                margin: 0;
            }
        }
         .card-block {
             padding: 26px;
             background-color: white;

             div:last-child {
                 p {
                     border-top: 1px solid $gray;
                     padding-top: 10px;
                 }
             }
         }

         &_wrapper {
             display: flex;
             justify-content: center;
         }
         
         &_blocks {
            margin-top: 20px;
             display: flex;
             gap: 10px;
         }
         &_block{
            &:hover{
                border-radius: 6px !important;
                transition: all .250s ease-in-out;
                background-color:#f6f9fe;
            }
         }
     }
   &__cities_cards{
    display: flex;
    gap: 20px;
    img{
        width: 458px;
        height: 378px;
        border-radius: 1.25rem;
        &:hover{
            transition: all .200s ease-in-out;
            border-radius: 10px; 
            transform: scale(1.030)
        }
        &:hover p{
            color:red;
        }
    }
   }
   
}
