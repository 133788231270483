.show-onScroll {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
}

.hide-onScroll {
    opacity: 0;
}

.animate-elem {
    transition: opacity .300s;
    transition: transform .500s;
}

.show-elem {
    transform: translate3d(0, 10px, 0);
}


.svg {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.body-before {
    background-color: #000 !important;
    // margin-top: 30px !important;
}

.body-after {
    background-color: #f6f6f7 !important;
    // margin-top: 110px !important;
}

body {
    transition: all .700s ease-in-out;
}